<template>
    <div class="pager">
        <div class="nav">
            <van-nav-bar :title="$t('C2CWithdrawal')" left-arrow @click-left="$router.go(-1)" />
        </div>

        <van-form input-align="right" label-width="150" @submit="onSubmit">

            <van-cell-group>
                <van-field :label="$t('BnakName') + '*'" :placeholder="$t('BnakName')" v-model="info.bank_name" />
                <van-field :label="$t('AccountName') + '*'" :placeholder="$t('AccountName')" v-model="info.pay_name" />
                <van-field :label="$t('AccountNumber') + '*'" :placeholder="$t('AccountNumber')"
                    v-model="info.bank_number" />
                <van-field :label="$t('BankCode')" :placeholder="$t('BankCode')" v-model="info.bank_code" />
                <van-field :label="$t('RoutingNumber')" :placeholder="$t('RoutingNumber')" v-model="info.route_code" />
                <van-field :label="$t('swiftCode')" :placeholder="$t('swiftCode')" v-model="info.swift" />
                <van-field :label="$t('BankAddress')" :placeholder="$t('BankAddress')" v-model="info.bank_address" />
                <van-field :label="$t('BranchCode')" :placeholder="$t('BranchCode')" v-model="info.branch_bank_code" />
                <van-field :label="$t('Remarks')" :placeholder="$t('Remarks')" v-model="info.remark" />
                <van-cell center>
                    <span style="color: #bfbfbf; font-size: 3.2vw;">
                        <img src="../../assets/img/asset-tips.svg" alt style="vertical-align: middle;" />
                        {{ $t('C2CWithdrawalTips') }}
                    </span>
                </van-cell>
            </van-cell-group>

            <div class="bottom">
                <div class="top">
                    <div class="title">{{ $t('Withdrawal') }} {{ $t('Amount') }}</div>
                    <span class="amount">{{ $t('Available') }} {{ available }} </span>
                </div>

                <div class="input-v">
                    <input type="digit" v-model="info.price" :placeholder="$t('PleaseEnterTheAmount')" />
                    <span class="divider" />
                    <span @click="info.price = available">{{ $t('All') }}</span>
                </div>

                <van-button class="submit" type="primary" size="normal" native-type="submit">{{ $t('Submit') }}</van-button>
            </div>

        </van-form>



    </div>
</template>
<script>
export default {
    data() {
        return {
            id: "",
            available: "0.00000",
            info: {
                price: "",// 金额,
                bank_name: "", // 银行名称
                pay_name: "", // 收款人姓名
                bank_number: "", // 银行卡号
                bank_code: "", // 银行代码
                route_code: "", // 路由代码
                swift: "", // swift
                bank_address: "", // 银行地址
                branch_bank_code: "", // 分行代码
                remark: "", // 备注
            }
        };
    },
    created() {
        this.id = this.$route.params.id;
        this.onLoad();
    },
    methods: {

        async onLoad() {
            const { data } = await this.$http.get("/home/user/index");
            if (data) {
                if (data.code === 200) {

                    const { usable = '0.00000' } = data.data.balance;
                    this.available = usable;
                }
            }
        },
        async onSubmit() { // 提交

            if (this.info.bank_name === "") {
                return this.$toast(this.$t('PleaseEnterTheBankName'));
            }
            if (this.info.pay_name === "") {
                return this.$toast(this.$t('PleaseEnterTheAccountName'));
            }
            if (this.info.bank_number === "") {
                return this.$toast(this.$t('PleaseEnterTheAccountNumber'));
            }
            if (this.info.price === "") {
                return this.$toast(this.$t('PleaseEnterTheAmount'));
            }

            const params = Object.assign({ c_id: this.id }, this.info);

            const { data } = await this.$http.post("/home/user/withdrawC", params);
            if (data) {
                if (data.code === 200) {
                    this.$toast(this.$t('C2CWithdrawalSubmitSuccess'));
                    this.$router.go(-1);
                } else {
                    this.$toast(data.msg);
                }
            }
        }

    },
};
</script>
<style lang="less" scoped>
.pager {
    position: relative;
    background: #f5f5f5;
    min-height: 100vh;
    height: auto;
    padding-top: 50px;
}

.nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.bottom {
    padding: 4.2666667vw 3.7333333vw;
    margin-top: 2.6666667vw;
    background-color: #fff;
    background: #fff;


    .top {
        display: flex;
        justify-content: space-between;

        .title {
            font-size: 3.7333333vw;
            line-height: 5.3333333vw;
            color: #000;
        }

        .amount {
            font-size: 2.6666667vw;
            color: #bfbfbf;
        }
    }

    .input-v {
        display: flex;
        height: 50px;
        padding: 13px;
        margin: 10px 0;
        border: 0.5px solid #e5e5e5;
        border-radius: 3px;

        input {
            flex: 1;
            border: none;
        }
    }

    .submit {
        margin-top: 15px;
        width: 100%;
        color: white;
        background: linear-gradient(270deg, rgb(76, 114, 255) 0%, rgb(73, 157, 255) 100%);
        border: 0px;
    }

    .divider {
        width: 1px;
        height: 4.2666667vw;
        margin: 0 3.2vw;
        background: #eee;
    }

}

::v-deep .van-field__label {
    color: #9097a7;
}

::v-deep .van-field {
    font-size: 3.7333333vw;
}
</style>
  